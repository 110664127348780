import React from 'react'
import {Link} from 'gatsby'
import {useTranslation} from "gatsby-plugin-react-i18next";

const SO = () => {
    const {t} = useTranslation();

    return (
        <section className="solutions-area pt-100px pt-100 pb-70">
            <div className="container">
                <div className="row">

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/talent-acquisition-strategy">
                                    {t('home.SOh31')}</Link>
                            </h3>
                            <p>{t('home.SOp1')}</p>
                            <Link className="view-details-btn" to="/talent-acquisition-strategy">
                                {t('EnSavoirPlus')}
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
                            <h3>
                                <Link to="/personal-profile-branding-et-marketing-personnel">
                                    {t('home.SOh32')}</Link>
                            </h3>
                            <p>{t('home.SOp2')}</p>
                            <Link className="view-details-btn" to="/personal-profile-branding-et-marketing-personnel">
                                {t('EnSavoirPlus')}
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>
                            <h3>
                                <Link to="/mobility-and-decision-support-solutions">
                                    {t('home.SOh33')}</Link>
                            </h3>
                            <p>{t('home.SOp3')}</p>
                            <Link className="view-details-btn" to="/mobility-and-decision-support-solutions">
                                {t('EnSavoirPlus')}
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>
                            <h3>
                                <Link to="/matching-candidature-interactif-et-benchmark-employabilité">
                                    {t('home.SOh34')}</Link>
                            </h3>
                            <p>{t('home.SOp4')}</p>
                            <Link className="view-details-btn" to="/matching-candidature-interactif-et-benchmark-employabilité">
                                {t('EnSavoirPlus')}
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>
                            <h3>
                                <Link to="/selfpromotion-and-talent-community">
                                    {t('home.SOh35')}
                                </Link>
                            </h3>
                            <p>{t('home.SOp5')}</p>
                            <Link className="view-details-btn" to="/selfpromotion-and-talent-community">
                                {t('EnSavoirPlus')}
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>
                            <h3>
                                <Link to="/prequalification-rgpd-and-anonymous-candidacy">
                                    {t('home.SOh36')}</Link>
                            </h3>
                            <p>{t('home.SOp6')}</p>
                            <Link className="view-details-btn" to="/prequalification-rgpd-and-anonymous-candidacy">
                                {t('EnSavoirPlus')}
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default SO